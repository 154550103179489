<template>
  <vue-ladda
    :loading="addloading"
    data-style="zoom-in"
    button-class="btn btn-info px-4"
    @click.prevent="AddToCart(ppm.id)"
    >{{ $t("user.showcase.updateCart") }}</vue-ladda
  >
</template>

<script>
import { GetDataManager } from "../../ds/index";
export default {
  name: "ShowcaseAddCart",
  data() {
    return {
      addloading: false,
    };
  },
  // model: {
  //   prop: "mm",
  //   event: "change",
  // },
  props: ["ppm"],
  methods: {
    AddToCart(ppm_id) {
      // console.log("Add to cart", ppm_id)
      let self = this;
      self.addloading = true;
      // console.log(this.$store.state.shop.market_cart)
      if (this.$store.state.shop.market_cart.id) {
        // console.log("Update cart")
        let params = {
          id: this.$store.state.shop.market_cart.id,
          add_ppm: ppm_id,
          add_qta: 1,
        };
        // console.log(params)
        GetDataManager("cart_usercarts")
          .update("", params)
          .then(
            (e) => {
              //   self.pp = e;
              // self.$emit("change", e);

              // self.active_loading = false;
              self.$store.commit("shop/setCart", e);
              self.addloading = false;
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              self.addloading = false;
              console.log(errors);
              // this.pp.is_active = !this.pp.is_active;
              // this.active_loading = false;
              // this.dataForm.errors = errors;
              // this.dataForm.loading = false;
            }
          );
      } else {
        console.log("create cart");
        let params = {
          market: this.$store.state.shop.market.id,
          family: 0,
          add_ppm: ppm_id,
          add_qta: 1,
        };
        // console.log(params)
        GetDataManager("cart_usercarts")
          .insert(params)
          .then(
            (e) => {
              //   self.pp = e;
              // self.$emit("change", e);

              // self.active_loading = false;
              // console.log(e)
              self.$store.commit("shop/setCart", e);
              self.addloading = false;
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              self.addloading = false;
              console.log(errors);
              // this.pp.is_active = !this.pp.is_active;
              // this.active_loading = false;
              // this.dataForm.errors = errors;
              // this.dataForm.loading = false;
            }
          );
      }
    },
  },
};
</script>
